/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');*/

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbb;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb;
}

.createOrJoin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color_mine_shaft_dark);

  /*background: -moz-linear-gradient(top, #901cec 0%, #5107a0 100%);
  background: -webkit-linear-gradient(top, #901cec 0%,#5107a0 100%);
  background: linear-gradient(to bottom, #901cec 0%,#5107a0 100%);*/

  /* background: var(--color_mine_shaft_light); */
}

.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
  /* width: 160px;
  height: 33px; */
}

.versionNumber {
  color: black;
  margin-top: -20px;
  font-size: smaller;
  margin-bottom: 10px;
}

.formWrapper {
  position: absolute;
  width: 355px;
  height: auto;
  border-radius: 12px;
  background: #ffffff;
  border-radius: 12px;
  color: white;
  padding: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0px 0px 100px 1px #ffffff82;
  -webkit-app-region: no-drag !important;
}

.title {
  font-size: 1.4rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  align-items: center;
  text-align: center;
  margin: 0 0 1rem;
  color: #006ecc;
}

.form {
  width: 100%;
  padding: 30px;
  margin-top: -20px;
  left: initial;
  /* margin-left: 40px; */
}

.form form {
  /* float: left; */
  /* margin-left: -20px; */
}

.titleInput,
.nameInput {
  display: block;
  /* width: 173px;
  height: 42px; */
  background: #ffffff;
  border-radius: 6px;
  margin: 0.5rem 0 0 0;
  padding: 0.75rem 1rem;
  /* font-size: 1rem; */
  border: 1px solid var(--color_alto);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
  background-color: transparent;

  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  /*font-weight: bold;*/
  font-size: 15px;
  /* line-height: 24px; */
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  color: #929090;
  border-top: none;
  width: 100%;
}

.titleInput:focus,
.nameInput:focus {
  color: #929090;
}
.button {
  border: none;
  width: 80%;
  height: 42px;
  background: #2d99e5;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: #ffffff;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 15px;
  outline: 0;
}

.button:hover {
  background: var(--background-color-gradiant-right);
}

.loginButton {
  border: none;
  width: 80%;
  height: 42px;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: #ffffff;
  align-items: center;
  text-align: center;
}
.joinWithDesktop{
  border: none;
  width: 100%;
  /* height: 42px; */
  background: #1a73e8;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: #ffffff;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  outline: 0;
}
.joinWithWeb{
  border: none;
  width: 100%;
  background: #2d99e5;
  /* height: 42px; */
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: normal;
  cursor: pointer;
  transition: background-color 0.15s;
  user-select: none;
  color: #ffffff;
  align-items: center;
  text-align: center;
  margin-top: 15px;
}
.margintop {
  float: right;
  margin-top: 35px;
}

.logoutButton {
  border-radius: 50% !important;
  width: 43px;
  height: 42px;
  cursor: pointer;
  transition: background-color 0.15s;
  color: #ffffff;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  margin-top: 15px;
  font-size: 26px;
  line-height: 42px;
}

.loginButtonColor {
  background: #72ad3e;
}

.logoutButtonColor {
  background: #fd5f03;
}

.logoutButtonColor:hover {
  background: #f06a1c !important;
}

.loginButton:hover {
  background: var(--color_green);
}
.loginLink {
  display: inline-block;
  color: white;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
}
.loginLink_url {
  display: inline-block;
  color:#555;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
}
.logoutOptions {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  background: orange;
  /* width: 95px; */
  text-align: center;
  padding: 11px;
  border-radius: 12px;
}

.loginLinkBlackColor {
  /* color: #555 !important; */
  text-decoration: none !important;
}

.loginLink:hover {
  text-decoration: underline;
}

.leftSide {
  float: left;
  color: #555;
}

.rightSide {
  float: right;
  color: blue !important;
}

.bgcolorRightSide {
  /* float: right; */
  background: #d9534f !important;
}

.bgcolorLeftSide {
  /* float: left; */
  background: #4cae4c !important;
}
.login-bottom-link {
  margin: -28px 0px 0px 0px;
  padding: 3px;
  display: table;
  width: 100%;
}
.login-bottom-link_url {
  margin-top: 6%;
  padding: 3px;
  display: table;
  width: 100%;
}

.login-bottom-link .logout {
  /* float: left; */
  margin: 15% 25%;
  /* margin-left: 10px; */
}

.login-bottom-link a {
  color: #555;
  cursor: pointer;
  transition: opacity 0.15s;
  text-decoration: none;
  float: left;
}

.login-bottom-link a:hover {
  text-decoration: none;
}

input {
  outline: 0;
}

.regionsList {
  margin-bottom: 0.75rem;
  border-radius: 0.25rem;
  margin-top: 0.5rem;
  border: 1px solid var(--color_alto);
  display: none;
}

.control {
  background-color: transparent;
  cursor: pointer;
  /* border: none !important; */
  outline: none !important;
  box-shadow: none !important;
  transition: none;
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 2.75rem;
  color: var(--color_tundora);
  /* display: flex; */
  align-items: center;
}
.joinMeetingText{
  color:#000;
}
.arrow {
  border-color: var(--color_tundora) transparent transparent;
  border-width: 0.3rem 0.3rem 0;
  margin-top: 0.25rem;
  margin-right: 0.25rem;
}

.dropdown[class~='is-open'] .arrow {
  border-color: var(--color_tundora) transparent transparent !important;
  border-width: 0.3rem 0.3rem 0 !important;
}

.menu {
  margin: 0;
  padding: 0.5rem;
  color: var(--color_tundora);
  background-color: var(--color_alabaster);
  box-shadow: 0 0 0 1px var(--color_alto),
    0 0.5rem 1rem var(--color_black_low_opacity);
  overflow: hidden;
  border: none;
  max-height: 16.5rem;
  border-radius: 0.25rem;
  overflow-y: scroll;
}

.menu *[class~='Dropdown-option'] {
  color: var(--color_tundora);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.menu *[class~='Dropdown-option']:hover {
  background-color: var(--color_mercury);
}

.menu *[class~='is-selected'] {
  background-color: transparent;
}

.menu *[class~='is-selected']:hover {
  background-color: var(--color_mercury);
}
.errorMessage {
  color: var(--color_thunderbird);
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 5px 5px 10px 5px;
  font-size: 0.8rem;
  width: 215px;
  margin:auto;
}
.successMessage {
  /*color: var(--color_mercury);*/
  color: var(--color_green);

  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  padding: 10px 5px;
  font-size: 0.8rem;
}

.loginLinkBottom {
  margin: 21rem 18rem 0px 0px;
}

.loginPageLink {
  margin: 21rem 5rem 0px 0px;
}

.joinPageLink {
  margin: 21rem 4rem 0px 0px;
}

.registerLinkBottom {
  margin: 23.5rem 19rem 0px 0px;
}
.continueWithParentClass{
  width:100%;
  display: flex;
  flex-flow: row wrap;
  padding: 2px;
}
.continueWithChild1Class{
  width: 20%;
  float: left;
  margin-top: 5px;
  margin-bottom: 5px;
}
.continueWithChild2Class{
  width: 80%;
  align-self: flex-end;
  margin-top: 5px;
  margin-bottom: 5px;
}
.text1{
  font-weight: bold;
  font-size: 16px;
  text-align: start;
  margin-left: 1px;
}
.text2{
  font-size: 13px;
  display: table;
  text-align: start;
  margin-left: 1px;
}
.downloadicon{
  height: 10%;
  width: 10%;
  margin-left: -5px;
  margin-top: 5px;
  position: absolute;
  object-fit: contain;
}
.downloadicon1{
  height: 50%;
  width: 60%;
  margin-left: 13px;
  margin-top: 28px;
  object-fit: contain;
}
.lounchicon{
  height: 10%;
  width: 10%;
  margin-left: -20px;
  margin-top: 10px;
  position: absolute;
  object-fit: contain;
}
.browsericon1{
  height: 7%;
  width: 8%;
  margin-left: -15px;
  margin-top: 0px;
  position: absolute;
  object-fit: contain;
}
.browsericon2{
  height: 7%;
  width: 8%;
  margin-left: -30px;
  margin-top: 25px;
  position: absolute;
  object-fit: contain;
}
.browsericon3{
  height: 7%;
  width: 8%;
  margin-left: 0px;
  margin-top: 25px;
  position: absolute;
  object-fit: contain;
}