/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.roster {
  /* color: var(--color_alabaster); */
  height: 100%;
  height: calc(47vh - 127px);
  display: table;
  overflow-y: auto;
  flex-direction: column;
  position: relative;
  border-top: 1px solid #dddcdd;
  color: black;
      width: 100%;
}

.noAttendee {
  color: var(--color_silver_chalice);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.attendees {
  flex: 0 0 3rem;
  overflow: hidden;
  display: flex;    
  align-items: center;
  height: 2.5rem;
  margin:  0 5%;
}

.rosterscroll {
    height: 200px;
    overflow-y: scroll;
    overflow-x: auto;
    height: calc(103vh - 34rem);
    width: 320px;
    /*background: aquamarine;*/
}

@media (min-height: 900px) {  
    .rosterscroll {
      height: 393px;
      overflow: scroll;
    }
}
.names {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  overflow-x: hidden;
}

.raisedHand {
  font-size: 1.3rem;
  margin-left: 0.5rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

.videos {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

.mutedd {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}
