/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
/*@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');*/

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbb;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bbb;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bbb;
}

.upcomingMeetings {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--color_mine_shaft_dark);

  /*background: -moz-linear-gradient(top, #901cec 0%, #5107a0 100%);
  background: -webkit-linear-gradient(top, #901cec 0%,#5107a0 100%);
  background: linear-gradient(to bottom, #901cec 0%,#5107a0 100%);*/

  /* background: var(--color_mine_shaft_light); */
}

.logo {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 20px;
  /* width: 160px;
  height: 33px; */
}
.join_logo{
  width: 22px;
  height: 22px;
  float:right;
}
.versionNumber {
  color: black;
  margin-top: -20px;
  font-size: smaller;
  margin-bottom: 10px;

}

.upcomingMeetings_formWrapper {
/*  position: absolute;
*/  width: 355px;
  height: auto;
  border-radius: 12px;
  background: #ffffff;
  border-radius: 12px;
  color: white;
  padding: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0px 0px 100px 1px #ffffff82;
  -webkit-app-region: no-drag !important;
}

.upcoming_meeting_loginLink {
  display: inline-block;
  color: white;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
}
.prevLink{
   display: inline-block;
  color: white;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
  text-align: center;
  margin-left: -60px;
  position: absolute;


}
.nextLink{
   display: inline-block;
  color: white;
  /* background: var(--color_alabaster); */
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin: 0.5rem;
  text-align: center;
  margin-left: 40px;
  position: absolute;
}
.olderLinkBlackColor {
  color: #2d99e5 !important;
  text-decoration: none !important;
}


.upcoming_meeting_loginLinkBlackColor {
  /* color: #555 !important; */
  text-decoration: none !important;
}

.upcoming_meeting_loginLink:hover {
  text-decoration: underline;
}

.upcoming_meeting_leftSide {
  float: left;
  color: #555;
}

.meetingsCard{
  margin-bottom: 10px;
  width: 90%;
  height: 65px;
  border-radius: 5px;
  background: #ffffff;
  color: white;
  padding: 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  -webkit-app-region: no-drag !important;
}
.meetingHeader{
  display: flex;
  justify-content: space-between;
}
.meetingParentDiv{
  display: flex;
  flex-direction: column;
  /* float: left; */
}
.meetingTitleId{
  color: #2d99e5;
  margin: 5px 10px 0px 10px;
  font-size: 15px;
  text-align: left;
  /* word-break: break-all; */
  /* float: left; */
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.meetingDateTime{
  background-color: red;
  margin-top: 5px;
  font-size: 10px;
  /* float: left; */
  margin-left: 10px;
  justify-content: center;
}
.meetingDate{
  color: #72ad3e;
  margin-top: 10px;
  font-size: 13px;
  display: flex;
  justify-content: space-between;
  /* text-align: left; */
  /* float: left; */
  margin-left: 10px;
}
.date_time{
  margin: 0;
  margin-right: 5px;
}
.meetingTime{
  color: #72ad3e;
  margin-top: 10px;
  font-size: 10px;
  float: right;
/*  margin-right: 10px;
*/}
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  background-color: #47ccde ;
  border-color: #47ccde ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #47ccde ;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #47ccde ;
  border-color: #47ccde;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}
/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */


 .login-bottom-link1 {
  /* margin: -28px 0px 0px 0px; */
  padding: 3px;
  display: table;
  width: 100%;
}
.upcoming_meeting_spinner {
  margin: auto;
  width: 20px;
  height: 20px;
  position: absolute;
  margin-top: 1%;
  margin-left: 145px;
}

.upcoming_meeting_circle {
  width: 100%;
  height: 100%;
  position: absolute;

}

.upcoming_meeting_circle::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color:#2d99e5;
  border-radius: 100%;
  animation: circleFadeDelay 1.2s infinite ease-in-out both;
}

.upcoming_meeting_circle2 {
  transform: rotate(30deg);
}

.upcoming_meeting_circle3 {
  transform: rotate(60deg);
}

.upcoming_meeting_circle4 {
  transform: rotate(90deg);
}

.upcoming_meeting_circle5 {
  transform: rotate(120deg);
}

.upcoming_meeting_circle6 {
  transform: rotate(150deg);
}

.upcoming_meeting_circle7 {
  transform: rotate(180deg);
}

.upcoming_meeting_circle8 {
  transform: rotate(210deg);
}

.upcoming_meeting_circle9 {
  transform: rotate(240deg);
}

.upcoming_meeting_circle10 {
  transform: rotate(270deg);
}

.upcoming_meeting_circle11 {
  transform: rotate(300deg);
}

.upcoming_meeting_circle12 {
  transform: rotate(330deg);
}

.upcoming_meeting_circle2::before {
  animation-delay: -1.1s;
}

.upcoming_meeting_circle3::before {
  animation-delay: -1s;
}

.upcoming_meeting_circle4::before {
  animation-delay: -0.9s;
}

.upcoming_meeting_circle5::before {
  animation-delay: -0.8s;
}

.upcoming_meeting_circle6::before {
  animation-delay: -0.7s;
}

.upcoming_meeting_circle7::before {
  animation-delay: -0.6s;
}

.upcoming_meeting_circle8::before {
  animation-delay: -0.5s;
}

.upcoming_meeting_circle9::before {
  animation-delay: -0.4s;
}

.upcoming_meeting_circle10::before {
  animation-delay: -0.3s;
}

.upcoming_meeting_circle11::before {
  animation-delay: -0.2s;
}

.upcoming_meeting_circle12::before {
  animation-delay: -0.1s;
}

@keyframes circleFadeDelay {
  0%,
  39%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.actionCell{
  display: flex;
  align-items: center;
}

.editIcon{
  height: 20px;
  width: 20px;
  object-fit: contain;
  cursor: pointer;
}
.deleteIcon{
  height: 20px;
  margin-left: 10px;
  width: 20px;
  cursor: pointer;
  object-fit: contain;
}