.diaplayPopUp {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  width: 150px;
  display: grid;
  justify-content: center;
  margin-bottom: 10px;
  margin-right: 5px;
  padding: 5px;
  position: absolute;
  bottom: 45px;
  right: 0;
}

@media (min-height: 900px) {  
  .diaplayPopUp {
      right: 0;
  }
}

.btn-leave {
  background-color: #d62f12;
  color: whitesmoke;
  margin-top: 0px;
  width: 140px !important;
  padding: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px !important;
  height: 28px !important;
  border-radius: 0px !important;
}

.btn-end {
  background-color: #d62f12;
  color: whitesmoke;
  margin-top: 3px;
  margin-bottom: 3px;
  width: 140px !important;
  padding: 5px;
  border: none;
  cursor: pointer;
  font-size: 16px !important;
  height: 28px !important;
  border-radius: 0px !important;
}

.btn-cancel {
  background-color: dimgray;
  color: white;
  margin-top: 3px;
  padding: 5px;
  cursor: pointer;
  border: none;
  font-size: 16px !important;
  width: 140px !important;
  height: 28px !important;
  border-radius: 0px !important;
}
