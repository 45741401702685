/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.remoteVideo {
  display: none;
  position: relative;
  background: transparent;
  /*overflow: hidden;*/
}

.largevideo {
        margin: 1%;
    width: 100% !important;
    height: 100% !important;
}

.muted {
  float: left;
    text-align: left !important;
    position: absolute;
    top: 1px !important;
    left: -6px !important;
    background: #ff0000b8;
    border-radius: 4px;
    width: 23px !important;
    text-align: center !important;
}
.buttonContainerRight {
  float: 'right';
  width: 100%;
  height: 100%;
  background-color: 'red';
  position: 'fixed';
}
.participantStatus {
    position: absolute;
    z-index: 9999;
    width: 94%;
    background: #0006;
    /* background: var(--video-name-plate-background-color); */
    border-radius: 4px;
    /* display: table; */
    margin-top: 100px;
    bottom: 5px;
    margin-left:3%;
}

.nameStatus .firstdiv {
    height: 31px;
    margin: 1px;
    line-height: 27px;
    cursor: pointer;
}
.nameStatus .firstdiv span {
  /* float: left; */
    margin: 0px 15px 0px 10px;
}

.nameStatus {
  display: inherit;
    /* flex-direction: column; */
    float: left;
    position: relative;
}

.participantStatusNew {
    position: absolute;
    display:table !important;
    right: 1%;
    z-index: 1;
    width: auto;
    padding: 10px;
    line-height: 15px;
    /* height: 18%; */
    bottom: 2%;
}

.attendee {
      z-index: 1;

}

/* .nameStatus i{
  float: left;
  line-height: 18px;
  margin-left:3px;
} */

.participantStatusWidht {
  width: auto !important; 
  right: 27% !important;
}

.participantStatusWithRightsideEnable {
   position: absolute;
   /* right: 350px !important; */
}

@media (min-height:900px) {
.participantStatusNew {
     right: 0.7%;
  }

.participantStatusWidht {
  width: auto !important; 
  right: 20% !important;
}
}

.displaynone {
    display: none !important;
}

.remoteVideo.roomMode {
  border-radius: 0.25rem;
}

.remoteVideo.screenShareMode {
  border-radius: 0.25rem;
}

.remoteVideo.enabled {
  display: flex !important;
  margin: 1%;
  height: 98%;
  width: 99%;
  position:  relative;
  border-radius: 6px;
  /*width: 188px;
    height: 127px;*/
}

.participants {
  position: relative;
    width: 100%;
    height: 131px;
    top: 2px;
    background: var(--video-background-color);
    backdrop-filter: blur(40px);
    border-radius: 6px;
    margin: 1%;
    z-index: 0;
}

.video {
  display: block;
    position: unset;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 8;
    width: 100%;
    height: 137px;
    border-radius: 6px;
    margin-left:0px;
}

.ddtt {
    font-size: 30px;
    background: red;
}

.removefullscreen {
  float: right;
    position: absolute;
    z-index: 9;
    right: 0;
    font-size: 25px;
    margin-right: -25%;
}


.video1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  object-fit: cover;
  z-index: 8;
}


.video i {
    color: #000;
    top: 40%;
    float: left;
    position: absolute;
    left: 4%;
}

.attname {
    color: #000;
    top: 0%;
    float: left;
    position: absolute;
    left: 4%;
}

.muted {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

.marginlefticon i{
  margin-left: 6px !important;
    float: left;
}

.manageheight i{ 
  line-height: 21px;
    margin: -1px 2px 0px 0px;
}

.nameplate {
  z-index: 1;
  align-items: center;
  position: absolute;
  max-width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color_black_medium_opacity);
  backdrop-filter: blur(0.5rem);
}

.remoteVideo.roomMode .nameplate {
  display: flex;
}

.remoteVideo.screenShareMode .nameplate {
  display: none;
}

.remoteVideo.medium .nameplate {
  padding: 0.25rem 0.3rem 0.25rem 0.5rem;
  bottom: 0.25rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
}

.remoteVideo.large .nameplate {
  padding: 0.5rem 0.75em 0.5em 1rem;
  bottom: 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  z-index: 9;
}

.marginleft {
  left: 12%;
}

.position {
  position: relative !important;
}
.fullscreen {
    position: fixed !important;
    /*z-index: 9;*/
    margin: 0% !important;
    border-radius: 0px !important;
}


.fullscreen1 {
    position: fixed;
    width: 70% !important;
}

@media only screen and (min-width: 870px) {
  .fullscreen1 {
      width: 61% !important;
  }
}

@media only screen and (min-width: 920px) {
  .fullscreen1 {
      width: 63% !important;
  }
}

@media only screen and (min-width: 970px) {
  .fullscreen1 {
      width: 65% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 36% !important;
  }
}

@media only screen and (min-width: 1024px) {
  .fullscreen1 {
      width: 68% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 30% !important;
  }
}

@media only screen and (min-width: 1060px) {
  .fullscreen1 {
      width: 69% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 30% !important;
  }
}

@media only screen and (min-width: 1100px) {
  .fullscreen1 {
      width: 70% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 30% !important;
  }
}

@media only screen and (min-width: 1165px) {
  .fullscreen1 {
      width: 71% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 30% !important;
  }
}

@media only screen and (min-width: 1200px) {
  .fullscreen1 {
      width: 72% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 30% !important;
  }
}

@media only screen and (min-width: 1250px) {
  .fullscreen1 {
      width: 73% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 30% !important;
  }
}

@media only screen and (min-width: 1275px) {
  .fullscreen1 {
      width: 74% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 27% !important;
  }
}

@media only screen and (min-width: 1300px) {
  .fullscreen1 {
      width: 75% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 25% !important;
  }
}

@media only screen and (min-width: 1350px) {
  .fullscreen1 {
      width: 76% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 25% !important;
  }
}

@media only screen and (min-width: 1400px) {
  .fullscreen1 {
      width: 77% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 24% !important;
  }
}

@media only screen and (min-width: 1450px) {
  .fullscreen1 {
      width: 83.5% !important;
  }
  .participantStatusWidht {
    width: auto !important; 
    right: 22% !important;
  }
}


.fullscreenTwo {
  position: absolute;
    bottom: 10% !important;
    left: 22%;
}

.displaynone {
    display: none !important;
}

/* .margintop {
    
    left: 28%;
    margin-top: 7px;
} */

.margintop1 {
    left: 100px;
    bottom: 75px !important;
}

.remoteVideo.small .nameplate {
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled .nameplate {
  display: none;
}

.remoteVideo.screenShareMode.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.name {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
  margin-left: 30px;
  line-height: 26px;
  display: flex;
  /* margin-top: -3px; */
}

.muted {
  flex: 0 0 1.25rem;
  width: 1.25rem;
  text-align: center;
}

.remoteVideo.small .name + .muted {
  margin-left: 0.25rem;
  text-align: center !important;
}

.remoteVideo.medium .name + .muted {
  margin-left: 0.25rem;
text-align: center !important;

}

.remoteVideo.large .name + .muted {
  margin-left: 0.5rem;
}
.volumecontrol {
  float: left;
  height: 10px;
  width: 3px;
  background: #8a8d8a;
  margin-left: 1px;
}

.mutemic {
    -ms-transform: rotate(116deg);
    transform: rotate(116deg);
    width: 3px;
    height: 27px;
    margin: -8px 0 0px -9px;
}
.raisedHand {
  z-index: 1;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  font-size: 2rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}
