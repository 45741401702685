/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
 .controls {
  /* display: inline-flex;
  position: relative; 

*/
  top: 15px;
    position: relative;
}

/* .controls > div {
  display: flex;
} */

.fullscreenButton {
    padding: 12px;
    background: #0006;
    border-radius: 9px;
    text-align: center;
    bottom: 9.5%;
    left: 23%;
    height: 49px;
    width: 49px;
    border: 1px solid #fff;
    line-height: 25px;
    margin-left: 5px;
    cursor: pointer;
    box-shadow: 2px 4px 4px 0px #11111173;
    -webkit-app-region: no-drag;
}
.bw-image {
  filter: grayscale(90%);
  -webkit-filter: grayscale(90%);
}
.button-left-side {
    float: left;
    width: 33.33% !important;
 }

.button-left-side img{
    cursor: pointer;
}

.button-center {
  justify-content: center;
  float: left;
  width: 33.33% !important;
  display: flex; 
}
.button-center img, .button-right-side img{
    cursor: pointer;
}

.reset-margin-left {
  padding-left: 10%;

   

}

.button-right-side {
    float: right;
    width: 33.33% !important;
    display: flex;
    justify-content: flex-end;
    
}
.button-right-side button {
    margin-right: 15px;
}

.msgcounter {
    margin: -11px 0px 0px -11px;
    float: left;
    position: absolute;
    height: 20px;
    width: auto;
    background: #de1010;
    z-index: 999 !important;
    padding: 6px;
    font-size: 13px;
    border-radius: 50%;
    line-height: 8px;
    font-weight: bold;
}

.controls.screenShareMode {
  width: calc(150px - 5rem);
  /*height: calc(var(--local_video_container_height) - 0.5rem);*/
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.15s;
  position: fixed;
    top: 0.25rem;
    margin-top: 3px;
    margin-left: 80px;
    
}

.controls.screenShareMode:hover {
  opacity: 1 !important;
}

.controls.screenShareMode.videoEnabled {
   opacity: 1; 
}

.controls.screenShareMode.audioMuted {
  opacity: 1;
}

.micMuted {
  display: none;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color_black_medium_opacity);
  text-align: center;
  justify-content: center;
  border-radius: 0.25rem;
  font-size: 1rem;
  padding: 1rem;
}

.controls.screenShareMode.audioMuted.videoEnabled .micMuted {
  display: flex;
}

.controls.screenShareMode.audioMuted.videoEnabled .muteButton {
  background-color: var(--color_thunderbird);
}

.controls button {
  width: 48px;
  height: 48px;
  backdrop-filter: blur(40px);
  border-radius: 8px;
  text-align: center;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  transition: opacity 0.15s;
  outline: none !important;
  z-index: 1;
}

.muteButton, .videoButton i {
  color:  #fff !important;
}

.controls button.enabled {
  color: var(--color_tundora);
  background: var(--color_alabaster);
}

.controls button:hover {
  opacity: 0.8;
}

.controls.roomMode button + button {
  margin-left: 1rem;
}

.controls.screenShareMode button + button {
  margin-left: 0.5rem;
}

.muteButton {
  width: 48px;
  height: 48px;
  background: #FF3459;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.button {
  width: 48px;
  height: 48px;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.float-left {
    float: left;
}

.red-background{
  background: var(--button-red-ground);

}

.green-background{

}

.white-background{

}

.focusButton {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.videoButton {
  width: 48px;
  height: 48px;
  background: #FF3459;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.shareButton {
  width: 48px;
  height: 48px;
  background: #0FDA24;
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.chatButton {
  width: 48px;
  height: 48px;
  background: #FFFFFF;
  backdrop-filter: blur(40px);
  border-radius: 8px;
      margin-left: 18px;

}

.shareButton i {
  color: #fff;
}


.endButton > i {
  font-size: 30px;
}

.endButton {
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.settingButton {
  width: 48px;
  height: 48px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 8px;
}

.settingButton i {
  font-size: 1.80rem;
}
