/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.screenShareHeader {
  display: table;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 0.25rem 0.5rem;
  flex-direction: column;
  position: absolute;
    top: 0;
    left: -15px;
}

.stopButton {
  border: none;
  border-radius: 0.25rem;
  padding: 0.5rem 0;
  font-size: 1rem;
  user-select: none;
  cursor: pointer;
  width: 100%;
  transition: opacity 0.15s;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  float: left;
  margin-left: 7px;
  left: 0px;
  position: relative;
}

.colornone {
    background: no-repeat;
}

.colornone i {
    font-size: 25px !important;
    color: #fff  !important;
}

.marginleft {
  margin-left: -4px !important;
}

.stopButton:hover {
  opacity: 0.8;
}

.description {
  margin-top: 0.25rem;
  color: var(--color_silver_chalice);
}
