/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.error {
  text-align: center;
  max-width: 50%;
  position: absolute;
  top:40%;
}

.errorMessage {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  font-size: 1.5rem;
}

.goHomeLink {
  display: inline-block;
  border: none;
  border-radius: 0.25rem;
  padding: 0.75rem 2rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--color_mine_shaft_light);
  background: var(--color_alabaster);
  cursor: pointer;
  transition: opacity 0.15s;
  user-select: none;
  text-decoration: none;
  margin-top: 2.5rem;
}

.goHomeLink:hover {
  opacity: 0.8;
}
