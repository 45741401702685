/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import '~@fortawesome/fontawesome-free/css/all.css';
@import '~rc-tooltip/assets/bootstrap.css';
@import '~react-dropdown/style.css';
@import '~react-datepicker/dist/react-datepicker.css';
/*@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap';*/
:root {
  --local_video_container_height: 150px;
  --right_panel_width: 320px;
  --screen_picker_width: 800px;
  --screen_picker_height: 600px;
  --color_white: #ffffff;
  --color_rightPanel: #04407b;
  --color_rightChat: #032648;
  --color_alabaster: #fafafa;
  --color_mercury: #e9e9e9;
  --color_alto: #dedede;
  --color_alto1: #373737;
  --color_silver_chalice: #9e9e9e;
  --color_tundora: #454545;
  --color_mine_shaft_dark: #7A06D6;
  --color_mine_shaft_medium: #446980;
  --color_mine_shaft_light: #555;
  --color_cod_gray_medium: #7A06D6;
  --color_cod_gray_light: #7A06D6;
  --color_black: #7A06D6;
  --color_thunderbird: #d62f12;
  --color_black_medium_opacity: rgba(0, 0, 0, 0.6);
  --color_black_low_opacity: rgba(0, 0, 0, 0.25);
  --color_green: #4BB543;
  --red_color: #FF3459;
  --button-red-ground: #FF3459;
  --baseDarkColor: #7A06D6;
  --baseLightColor: #901CEC;
  --background-color-gradiant-left: #004DFF;
  --background-color-gradiant-right: #1C429A;
  --video-background-color: #021b56;
  --middle-blue-color: #153379;
  --video-name-plate-background-color: #010b22;
}


body {
  position: relative;
  color: var(--color_alabaster);
  /*background-color: var(--color_mine_shaft_light); */

  /*background: -moz-linear-gradient(top left, var(--background-color-gradiant-left) 0%, var(--color_mine_shaft_light) 100%);  */
  background: -webkit-linear-gradient(top left, var(--background-color-gradiant-left) 0%,var(--background-color-gradiant-right) 100%);
  background: linear-gradient(to bottom right, var(--background-color-gradiant-left) 0%,var(--background-color-gradiant-right) 100%);


  height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  line-height: 1.45;
}

#root {
  height: 100%;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
}
