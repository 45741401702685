/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

/*video{
 object-fit: cover !important;
}
*/

.newheader {
    float: left;
    position: fixed;
    top: 0;
    height: 5%;
    width: 100%;
    background: #11368A;
    z-index: 12;
}

.newheader .logo {
    float: left;
    width: 100%;
    /* position: relative;
    text-align: center; */
    height: 100%;
    margin: 0 auto;
    /* vertical-align: middle; */
    display: flex;
    align-content: flex-end;
    justify-content: center;
}

.newheader .logo img{
  vertical-align: middle;
    /* margin-top: 3px; */
    /* height: 100%;
    width: 166px; */
}

.newheader .rightside {
    /* width: 14%; */
    float: right;
    position: inherit;
    height: 5%;
    right: 50px;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-content: stretch;
    align-items: center;
    
}
  

.newheader .rightside img{ 
    float: left;
    position: relative;
    line-height: 20px;
    cursor: pointer;
    margin: 0px 14px 0px 0px;
    height: 30px;
    width: 30px; 
    cursor: pointer;   
}

.newheader .rightside .userlogo {
    height: 30px;
    width: 30px;
    float: left;
    border-radius: 50%;
    margin: 0px 14px 0px 0px;
    line-height: 29px;
    background: var(--background-color-gradiant-left);
    border: none;
    text-align: center;
    line-height: 28px;  
    cursor: pointer;  
}

.newheader .menuTab {
    float: right;
    position: relative;
    margin-right: 4%;
    z-index: 12;
    background-color: #ffffff99;
    border-radius: 8px;
    display: grid;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px;
}

.newheader .menuTab .subtab{
      float: right;
    height: 20px;
    background: red;
    width: 110px;
    height: 27px;
    line-height: 15px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
}

.msgreceived-main {
  position: absolute;
  right: 0;
  float: right;
  height: 100%;
  width: 260px;
  z-index: 12;
}

.msgreceived-main .msgreceived .msgdelete {
  float: right;
  position: absolute;
  margin: -5px -2px 0 0;
  text-align: right;
  color: #ffffff;
  font-size: 17px;
  cursor: pointer;
  z-index: 10;
  right: 15px;
}

.msgreceived-main .msgreceived {
  height: 65px;
  width: 250px;
  background: var(--middle-blue-color);
  z-index: 99;
  border-radius: 10px;
  margin-top: 10px;
  border: 1px solid;
  padding: 5px;
  box-shadow: 0px 5px 8px 0px #6f0bbead;
}

.msgreceived-main .msgreceived .msgreceived-title {
  float: left;
  text-align: center;
  cursor: pointer;
  height: 55px;
  position: relative;
  margin: 0 auto;
  width: 92%;
  font-size: 16px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 26px;
}

.msgreceived-main .msgreceived .msgreceived-detail {
  float: left;
  width: 97%;
  height: 31px;
  text-align: left;
  margin-left: 10px;
  padding: 3px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contentVideoSmall {
  top: 11%;
  position: relative;
  float: right;
  /*height: 20% !important;*/
  /*transition: all .5s linear;*/
  /*background: #fff;*/
  border-radius: 10px;
  z-index: 9;
  right: 4%;
  width: 199px;
  height: 131px;
  background: #fff;
  border: 2px solid #000;
}
.contentVideoSmallWithFullscreenAndRightsideEnable{
  top: 10%;
  position: relative;
  float: right;
  /*height: 20% !important;*/
  /*transition: all .5s linear;*/
  /*background: #fff;*/
  border-radius: 10px;
  z-index: 9;
  right: 6%;
  width: 199px;
  height: 131px;
  background: #fff;
  border: 2px solid #000;
}
.msgcounter1 {
  margin: -11px 0 0px 26px;
  float: left;
  position: absolute;
  height: 20px;
  width: auto;
  background: #de1010;
  z-index: 999 !important;
  padding: 6px;
  font-size: 13px;
  border-radius: 50%;
  line-height: 8px;
  font-weight: bold;
}

.videoloading {
  text-align: center;
  position: absolute;
  float: left;
  top: 30%;
  left: 36%;
}

.video-title {
  float: left;
  position: absolute;
  margin-top: 66%;
  height: 27px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.classroom {
  display: flex;
  height: 100%;
  justify-content: center;
}

.tabsection {
  color: #555;
  position: absolute;
  /*padding: 0.5rem;*/
  width: 100%;
  z-index: 0;
}

.tabtitleDIV {
  /*padding: 0.5rem;*/
  /*margin-top: 35px;*/
  /*margin-left: 15px;*/
}
.tabtitle {
  /*float: left;
    width: 65%;*/

  float: left;
  width: 100%;
  /*padding: 0.5rem;*/
  /*margin-top: 50px;*/
  margin-left: 15px;
}

.tabtitle .titlename {
  height: 25px;
  overflow: hidden;
}

.tabtitle h3.titlename span{
  font-size: 15px;
  font-style: italic;
}

.copylink {
  float: right;
  width: 15%;
  margin-top: 4px;
  background: url('../public/icons/invite.png');
  background-repeat: no-repeat;
}

.odvmain {
    /*float: left;*/
    margin: 2%;
    margin-top: 97%;
    /*background: pink;*/
    position: relative;
    height: calc(103vh - 26em);
    overflow-y: scroll;
    overflow-x: auto;
}

.right-side-section {
  position: absolute;
  z-index: 9;
  margin: 31px 0 0 26px;
  left: 0;
  -webkit-app-region: no-drag;
}

.odvmain .videolist {
  float: left;
  width: 100%;
  /* background: pink; */
  border-bottom: 1px solid #cbcaca;
  height: 65px;
  overflow: hidden;
}

.odvmain .videolist .thumbnail {
  float: left;
  width: 32%;
  margin: 1%;
}

.odvmain .videolist .thumbnail img {
  width: 85px;
  height: 55px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px -1px #e1dede;
  cursor: pointer;
}

.odvmain .videolist .title {
  float: left;
  width: 65%;
  font-size: 14px;
  overflow: hidden;
  cursor: pointer;
  text-align: left;
  color: #555;
}

.odvmain .videolist .title .titlename {
  font-weight: bold;
  overflow: hidden !important;
  text-overflow: ellipsis;
  width: 100%;
  height: 22px;
  /*background: pink;*/
  font-size: 14px;
}

.copied {
  color: green;
  float: left;
  margin: -5px 0px 0px -72px;
}
.tabtitle h3 {
  color: #555 !important;
  width: 100%;
}

.tabtitle h3 a {
  float: right;
  margin-right: 30px;
  cursor: pointer;
}

.videoright::after {
  position: absolute;
  z-index: 999;
  height: auto;
  width: auto;
  top: 30%;
  right: 2%;
}

/* .my-video-dimensions {
  height: 302px !important;
  width: 180px !important;
}

.my-video-dimensions::after {
  height: 302px !important;
  width: 180px !important;
} */

.videoright {
    position: absolute;
    z-index: 10;
    height: auto;
    width: auto;
    top: 180px;
    right: 1%;
}

.video-js::after {
  right: 30px !important;
  position: absolute !important;
  z-index: 9999 !important;
  top: 49% !important;
}

.video-js {
  right: 0px !important;
  position: absolute !important;
  z-index: 9999 !important;
  top: 49% !important;
}

.no-video {
  background: #ccc !important;
  font-size: 13px;
  height: 100%;
  width: 100%;
}

.no-video h3 {
  margin: 0 auto;
  text-align: center;
}

.livestream-list {
  margin-top: 75%;
  text-align: center;
}

.livestream-list .list {
  text-align: center;
}

.livestream-list .list h3 {
  text-align: center;
}

.classroom::after {
  content: '';
  width: 170px;
  height: 35px;
  /* background:url("https://roomzzconnect.s3.amazonaws.com/logo.png"); */
  /* opacity: 0.2; */
  /*top: 30px;
  left: 20px  ;*/
  top: 35px;
  left: 35px;
  position: absolute;
  z-index: 1;
}

.minmaxClass {
  height: 7% !important;
  top: 2px !important;
  left: 80.8% !important;
  position: absolute !important;
  /*-moz-transition: opacity 0.4s ease-in-out;
    -o-transition: opacity 0.4s ease-in-out;
    -webkit-transition: opacity 0.4s ease-in-out;
    transition: opacity 0.4s ease-in-out;*/
}

.minmaxClassLeft {
  height: 7% !important;
  top: 2px !important;
  left: 48.6% !important;
  position: absolute !important;
}

.marginRight {
  /*margin-left: 86px !important;*/
}

.marginRight1 {
  /*margin-left: 215px !important;*/
}

.classroom.isModeTransitioning::after {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color_mine_shaft_light);
  z-index: 10;
}

.left {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  /*height: 100%;*/
}

.contentVideoWrapper {
  /*display: none;
  flex: 1 1 auto;
  overflow-y: hidden;*/

  /*background: orange;*/
  width: 100%;
  height: 100%;
  /*overflow: scroll;*/
  /*margin: 1.5%;*/
  /*z-index: 1;*/
}

.classroom.isContentShareEnabled .contentVideoWrapper {
  display: block;
}

.classroom.screenShareMode .contentVideoWrapper {
  display: none !important;
}

.remoteVideoGroupWrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.classroom.roomMode.isContentShareEnabled .remoteVideoGroupWrapper {
  flex: 0 0 auto;
}

.classroom.nologo {
  background-image: none !important;
  top: 0px !important;
  left: 0px !important;
}

.nologo::after {
  background-image: none !important;
  top: 0px !important;
  left: 0px !important;
  height: 0;
}



.localVideoWrapper {
    position: absolute;
    /* width: 97%; */
    left: 20px;
    bottom: 15px;
    z-index: 10 !important;
    /*background: pink;*/
    -webkit-app-region: no-drag;
    right: 20px;
}

.localVideoWrapper-resize {
    width: 75%;
    width: -webkit-calc((100% - 23em) + 3px) !important;
    
}

@media (min-height: 900px) {  
  .localVideoWrapper-resize  {
      /* width: 82% !important; */
  }
  .contentVideoSmall {
      top: 10%;
      right:5%;
  }
  .odvmain {
      margin-top: 103%;
      height: 470px;

  }
  .video-title {
      margin-top: 74%;
  }

  .file-upload-input {
      height: 36% !important;
  }
}

.localVideo {
  position: absolute;
  right: 0.25rem;
}

.classroom.screenShareMode .localVideo {
  right: auto;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
}

.classroom.screenShareMode .controls {
  z-index: 1;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 0 0 var(--right_panel_width);
  background: var(--color_rightPanel);
  height: 95%;
  /*overflow: hidden;*/
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 9;
  top: 5%;
  /*margin: 1%;*/
  /* border-radius: 8px; */
  margin-left: 0;
  transition: all 1s linear;
  -webkit-app-region: no-drag;
}

.classroom.screenShareMode .right {
  display: none;
}


.mainDiv {
  float: left;
  width: 100%;
  display: contents;
}

.titleWrapper {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--color_mine_shaft_light);
}

.title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

.label {
  font-size: 0.8rem;
  color: var(--color_white);
}

.deviceSwitcher {
  flex: 0 1 auto;
}

.broadcast {
  flex: 0 1 auto;
}

.roster {
  flex: 1 1 auto;
  /* overflow-y: scroll; */
  height: 50%;
  position: absolute;
  width: 320px;
  height: 100px;
  /* top: 82px; */
  color: #000000;
}

.modal {
  outline: none;
}

.modalOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(0.5rem);
  -webkit-app-region: no-drag;
}

.settingButton {
  display: flex;
  align-items: flex-start;
}
.settingButton button {
  background: none;
  border: none;
  outline: none;
  color: var(--color_white);
  font-size: 22px;
}

.tabs {
  position: relative;
  width: 320px;
  z-index: 9;
  background: #ffffff;
  display: contents !important;
  box-shadow: 0px 1.5px 0px rgba(0, 0, 0, 0.1);
}
.hw {
  height: 43px;
  width: 43px;
}
.tabsTitle {
  display: flex;
    flex-direction: row;
    width: 100%;
    /*background: orange;*/
    border-bottom: 2px solid #ccc;
    height: 50px;
}

.tabsTitle .tab {
   padding: 5px;

}
.tabs-heading {
  width: 100%;
  /* height: 30px; */
  /*background: pink;*/
  float: left;
  color: #000;
}

.tabs-heading .meeting-id {
  float: left;
  line-height: 35px;
  cursor: pointer;
  position: relative;
  margin-left: 20px;
}

.tabs-heading .close-settings-button {
  float: left;
  margin: 0px 0 0 -20px;
  position: relative;
  cursor: pointer;
  height: 35px;
  width: 35px;
  /*background: #fff;*/
  -webkit-app-region: no-drag;
}

.tabs-heading .close-settings-button img {
  float: left;
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.tabsTitle .tab img {
  height: 43px;
  width: 43px;
  cursor: pointer;
}

.cols {
  float: left;
  padding: 0px 0px 5px 10px;
  text-align: center;
  color: black;
}
.cols span {
  display: block;
}
.cols .uploadBut {
  margin: 45px 0;
  background: var(--color_mine_shaft_light);
  border: 0;
  color: var(--color_white);
  padding: 10px 30px;
  font-size: 20px;
  border-radius: 5px;
}
.progress-bar {
  width: 200px;
  position: relative;
  height: 8px;
  margin-top: 4px;
}
.progress-bar .progress {
  height: 8px;
  background-color: var(--color_mine_shaft_light);
  width: 0;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 41%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin: 10px;
  margin-top: 21%;
  box-shadow: 0px 5px 15px 5px #e8e5e5;
  border-radius: 30px;

}

.backgroundImg {
  /*background: url('') !important;*/
}

/*.image-dropping,
.image-upload-wrap:hover {
  border: 4px dashed #ffffff;
}*/

.drag-text {
  text-align: center;
  padding-top: 8%;
  padding-bottom: 6%;
  height: 300px;
}

.drag-text i {
  /*color: #fff;*/
  font-size: 30px;
}

.drag-text h2 {
  font-weight: 100;
  text-transform: uppercase;
  /*color: #fff;*/
}

.drag-box .first-div {
  float: left;
  width: 100%;
  position: relative;
}

.drag-box .first-div .first-span {
  width: 100%;
  padding: 2%;
  font-weight: bold;
  font-size: 21px;
  color: #74809d;
}

.file-status {
  font-size: larger;
  margin-top: 5px;
  color: black;
}

.drag-box .first-div .second-span {
  width: 100%;
  padding: 2%;
  font-weight: bold;
  font-size: 18px;
  color: #c7ccd9;
}

.drag-box .img-div {
  padding: 4%;
}


.drag-box .img-div img{
      height: 45px;
}

.drag-box .dashed-div{
      /* float: left; */
    border: 3px dashed #ccc !important;
    padding: 4%; 
    border-radius: 15px;

    width: 96%;
    margin: 0 auto;
}

.drag-box .dashed-div .content {
  font-size: 15px;
    margin: 0%;
    padding: 2.5%;
}

.drag-text i {
  /*color: #fff;*/
  font-size: 30px;
}

.drag-text h2 {
  font-weight: 100;
  text-transform: uppercase;
  /*color: #fff;*/
}

.listName {
  padding-top: 20px;
}
.popupBox {
  position: absolute;
  z-index: 999;
  width: 100%;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
}
.popupImg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.popupClose {
  float: right;
  padding: 10px;
  cursor: pointer;
}

.popupFile {
  display: block;
  margin: 30px;
  width: '800px !important';
  height: '520px !important';
}

.settingRightButton {
  position: static;
  width: 20px;
  height: 20px;
  left: 0px;
  top: 0px;

  /*background: url('../public/icons/settings-icon.png');*/

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: center;
  margin: 0px 23px;
}

.livebroadcast {
  position: static;
  width: 20px;
  height: 20px;
  left: 132px;
  top: 0px;

  background: url('../public/icons/broadcast-live.png');

  /* Inside Auto Layout */

  flex: none;
  order: 4;
  align-self: center;
  margin: 0px 23px;
}

.vod {
  position: static;
  width: 20px;
  height: 20px;
  left: 132px;
  top: 0px;

  background: url('../public/icons/vod.png');

  /* Inside Auto Layout */

  flex: none;
  order: 4;
  align-self: center;
  margin: 0px 23px;
}
.chatBox {
  position: static;
  width: 20px;
  height: 20px;
  left: 66px;
  top: 0px;
  /* Inside Auto Layout */
  flex: none;
  order: 1;
  align-self: center;
  margin: 0px 23px;
  background: url('../public/icons/vector.png');
}

.fileBox {
  position: static;
  width: 20px;
  height: 20px;
  left: 132px;
  top: 0px;

  background: url('../public/icons/icons-add-file.png');

  /* Inside Auto Layout */

  flex: none;
  order: 2;
  align-self: center;
  margin: 0px 23px;
}

.meetingTitle {
  position: absolute;
  /* width: 153px; */
  height: 7.33px;
  top: 45px;

  /* font-family: Montserrat; */
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  padding: 0px 20px;

  /* Black/100% */

  color: #000000;
}

.chat {
  /* position: relative;
  width: 240px;
  height: 560px; */

  /* White/100% */
}

.chatTitleBox {
  position: absolute;
  width: 240px;
  height: 72px;
  top: 50px;
}

.chatTitle {
  position: absolute;
  width: 39px;
  height: 16px;

  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;
  padding: 0px 20px;

  /* Black/100% */

  color: #000000;
}

.chatParticipants {
  position: absolute;
  width: 208px;
  height: 16px;
  top: 40px;
}

.chatIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  margin-left: 270px;
  background: url('../public/icons/more-elipses.png');
}

.chatParticipantsIcon {
  position: absolute;
  width: 8px;
  height: 5px;
  margin-left: 295px;
  background: url('../public/icons/more-participants.png');
}

.fileSharedTitle {
  position: absolute;
  /* width: 153px;
  height: 7.33px; */
  top: 55px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-left: 12px;
}


.uploadMessages {
  margin-top: 3%;
  overflow-y: scroll;
  overflow-x: auto;
  padding: 1%;
 max-height: calc(86vh - 26rem);
 display: flex !important;
 flex-direction: column-reverse;
}


.uploadedFiles {
  padding: 0px;
  cursor: pointer;
}

.participants {
  position: relative;
  width: 176px;
  height: 97%;
  top: 2px;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(40px);
  border-radius: 8px;
  margin: 1%;
  z-index: 9;
}
