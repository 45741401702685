/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */
.isSpeakerActive{
  border: solid 4px;
  border-color: #35fc03;
}
.participants {
  position: relative;
  width: 100%;
  height: 131px;
  top: 2px;
  background: var(--video-background-color);
  border-radius: 6px;
  margin: 1%;
  z-index: 0;
}

.videoTileView{
  border-radius: 8px;
  width: 100%;
  position: relative;
  /* background-color: lightseagreen; */

 /* justify-content: space-around; */
}
/* .attendeeTileView{ */
  /* width: 100%; */
  /* display:grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 0.5fr)); */
  /* grid-gap: 10px;  */
  /* justify-content: center;
}  */
.attendeeTileView{
  position: relative;
  /* top: 50%; */
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: lime; */
}

.videocenter {
  float: left;
  position: fixed;
  height: 86%;
  width: 97% !important;
  left: 0%;
  top: 3%;
}
.videocenter1 {
  float: left;
  position: fixed;
  height: 84%;
  width:65%;
  left: 25%;
  top: 4%;
  background: var(--video-background-color);
  margin:1%;
  border-radius: 8px;
}
.isRightbarVideo{
  float: left;
  position: fixed;
  height: 85%;
  width:53%;
  left: 21%;
  top: 4%;
  background: var(--video-background-color);
  margin:1%;
  border-radius: 8px;
}
.videostart {
  overflow-x: auto;
  overflow-y: scroll;
  height: calc(100vh - 150px);
  margin-top: 5px;
  scrollbar-width: none;
}
.remoteVideoGroup.tileView {
  /* margin: 5.3%; */
  float: right;
  border-radius: 8px;
  width: calc(98vmax);
  position: relative;
  /* margin-right: 30px; */
  height: 88vh;
}
.remoteVideoGroup.tileViewRightBarEnabled{
  float: right;
  border-radius: 8px;
  width: 74vw;
  position: relative;
  height: 88vh;
}
@media (max-height:490px) {
  .videostart {
    overflow-y: scroll;
    /*height: 370px;*/
      
  }
}

@media (max-height:800px) {
  .videostart {
    overflow-y: scroll;
      
  }
}

@media (max-height:900px) {
  .videostart {
    overflow-y: scroll;
    /*height: 100%;*/
  }
}

@media (min-height:900px) {
  .videocenter
  {
    height: 87.5%;
    width: 95%;
    left: -4px;
    top: 0%;
  }
  .videocenter1{
    height: 88.5%;
    width: 95%;
    /* left: 23%; */
    top: 1%;
  }

}
@media only screen and (min-width: 700) {
 
  .videocenter1 {
    width: 65%;
    left:30%;
  }
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }

}
@media only screen and (min-width: 1024) {
  .videocenter {
    width: 76%;
  }
  .videocenter1 {
    width: 76%;
  }
  .fullscreen1 {
    width: 58%;
  }
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }
}

@media only screen and (min-width: 1060) {
  .videocenter {
    width: 77%;
  }
  .videocenter1 {
    width: 77%;
  }
  .fullscreen1 {
    width: 60%;
  }
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }
}

@media only screen and (min-width: 1100) {
  .videocenter {
    width: 78%;
  }
  .videocenter1 {
    width: 64%;
  }
  .fullscreen1 {
    width: 63% !important;
  }
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }
}

@media only screen and (min-width: 1165px) {
  .videocenter {
    width: 79%;
  }
  .videocenter1 {
    width: 64%;
    left: 27%;
  }
  .fullscreen1 {
    width: 71% !important;
  }
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }
}

@media only screen and (min-width: 1200px) {
  .fullscreen1 {
    width: 72% !important;
  }  
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }
}

@media only screen and (min-width: 1250px) {
  .videocenter {
    width: 80%;
  }
  .videocenter1 {
    width: 64%;
    left: 27%;
  }
  .fullscreen1 {
    width: 73% !important;
  }
  .isRightbarVideo{
    width:43%;
    left: 25%;
  }
}

@media only screen and (min-width: 1275px) {
  .fullscreen1 {
    width: 68% !important;
  }
  .isRightbarVideo{
    width:45%;
    left: 25%;
  }
}

@media only screen and (min-width: 1300px) {
  .fullscreen1 {
    width: 75% !important;
  }
  .isRightbarVideo{
    width:45%;
    left: 24%;
  }
}

@media only screen and (min-width: 1350px) {
  .videocenter {
    width: 96.5%;
  }
  .videocenter1 {
    width: 63%;
  }
  .fullscreen1 {
    width: 70%;
  }
  .isRightbarVideo{
    width:53%;
    left: 21%;
  }
}

@media only screen and (min-width: 1400px) {
  .fullscreen1 {
    width: 71%;
  }
  .isRightbarVideo{
    width:53%;
    left: 21%;
  }
}

@media only screen and (min-width: 1450px) {
  .videocenter {
    width: 85%;
  }
  .videocenter1 {
    width: 63%;
  }
  .fullscreen1 {
    width: 64%;
  }
  .remoteVideoGroup.tileView {
    width: calc(96vw);
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    width: 76vw;
  }
  .isRightbarVideo{
    width:53%;
    left: 21%;
  }
  
}

@media only screen and (min-width: 1550px) {
  .videocenter {
    width: 83%;
  }
  .videocenter1 {
    width: 65%;
  }
  .remoteVideoGroup.tileView {
    width: calc(96vw);
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    width: 79vw;
  }
  .isRightbarVideo{
    width:53%;
    left: 21%;
  }
}

@media only screen and (min-width: 1650px) {
  .videocenter {
    width: 84%;
  }
  .videocenter1 {
    width: 65%;
    left: 24%;
  }
  .remoteVideoGroup.tileView {
    width: calc(96vw);
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    width: 80vw;
  }
  .isRightbarVideo{
    width:53%;
    left: 21%;
  }
}



.displaynone {
  display: none !important;
}

.right-video-main {
  background: rgba(255, 255, 255, 0.6);
}

.rightmain {
  width: 100%;
  z-index: 1;
  margin-top: 1%;
  display: inline-flex;
  align-content: center;
  align-items: flex-end;
}

.rightmainleft {
  margin-right: 2px;
  float: left;
  width: 100%;
}



::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

@media (min-width:48rem) {
   :root {
    --fixed-header-height: 10rem;
    --scrolled-header-height: 0rem;
    --root-side-padding: 5.0rem
  }
}

@media (min-width:85rem) {
   :root {
    --root-side-padding: calc((100% - 80rem) / 2)
  }
}

@media (prefers-color-scheme:dark) {
  [data-theme=auto] {
    --secondary-text-color: #fff;
    --primary-text-color: #f3f4f5;
    --accent-color: var(--brand-green);
    --interaction-active-state-color: #f3f4f5;
    --label-text-color: #f0f0f0;
    --subline-text-color: #b8b8b8;
    --interaction-unactive-state-color: #b8b8b8;
    --divider-color: #2b2b2b;
    --elevated-background-color: #1a1a1a;
    --primary-background-color: #000;
    --active-element-highlight-color: var(--brand-green);
    --active-element-highlight-shadow-filter: drop-shadow(0 0.3125em 1.25em rgba(224, 250, 82, 0.15))
  }
}

[data-theme=enforced-dark] {
  --secondary-text-color: #fff;
  --primary-text-color: #f3f4f5;
  --accent-color: var(--brand-green);
  --interaction-active-state-color: #f3f4f5;
  --label-text-color: #f0f0f0;
  --subline-text-color: #b8b8b8;
  --interaction-unactive-state-color: #b8b8b8;
  --divider-color: #2b2b2b;
  --elevated-background-color: #1a1a1a;
  --primary-background-color: #000;
  --active-element-highlight-color: var(--brand-green);
  --active-element-highlight-shadow-filter: drop-shadow(0 0.3125em 1.25em rgba(224, 250, 82, 0.15))
}

*,
*::before,
*::after {
  box-sizing: border-box;
  /* z-index: 1 !important; */
}

.rightmain .minimize {
  float: right;
  /*margin-left: 6px;*/
}

.rightmain .maximize {
  float: right;
  /*margin-left: 6px;*/
}
.rightmain .tileViewIcon{
  float: right;
  /*margin-left: 6px;*/
}

.remoteVideoGroup {
  padding: 4px;
  float: right;
  display: grid;
}

.remoteVideoGroup.roomMode {
  top: 5%;
  float: right;
  border-radius: 8px;
  width: 230px;
  position: fixed;
  margin-left: 12px;
}

@media (max-height: 800px) { 
  .videocenter video {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    margin: 0.5%;
    z-index: 1;
  } 
  /* .remoteVideoGroup.tileView {
    float: right;
    border-radius: 8px;
    width: calc(96vw);
    position: relative;
    margin-right: 30px;
    height: 88vh;
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    float: right;
    border-radius: 8px;
    width: 74vw;
    position: relative;
    height: 88vh;
  } */

}

@media (max-height: 600px) { 
  .remoteVideoGroup.tileView {
    height: 85vh;
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    height: 85vh;
  }

}
@media (max-height: 500px) { 
  .remoteVideoGroup.tileView {
    height: 83vh;
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    height: 83vh;
  }

}
@media (max-height: 400px) { 
  .remoteVideoGroup.tileView {
    height: 80vh;
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    height: 80vh;
  }

}
@media (max-width: 1200px) { 
  .remoteVideoGroup.tileView {
    width: calc(96vw);
    /* height: 86vh; */
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    width: 64vw;
    /* height: 86vh; */
  }

}
@media (max-width: 1000px) { 
  .remoteVideoGroup.tileViewRightBarEnabled{
    width: 60vw;
    /* height: 88vh; */
  }

}
@media (max-width: 850px) { 
  .remoteVideoGroup.tileViewRightBarEnabled{
    width: 57vw;
    /* height: 88vh; */
  }

}
@media (min-height: 800px) {  
  .videocenter video {
    float: left;
    position: absolute;
    top: 0;
    left: 0;
    height: 103%;
    width: 100%;
    border-radius: 8px;
    margin: 0.5%;
    z-index: 1;
  }
  /* .remoteVideoGroup.tileView {
    float: right;
    border-radius: 8px;
    width: calc(96vw);
    position: relative;
    margin-right: 30px;
    height: 89vh;
  }
  .remoteVideoGroup.tileViewRightBarEnabled{
    float: right;
    border-radius: 8px;
    width: 74vw;
    position: relative;
    height: 89vh;
  } */

}
.remoteVideoGroup.roomMode.isContentShareEnabled {
  /*height: var(--local_video_container_height);*/
}

.remoteVideoGroup.screenShareMode {
  padding: 0 0.25rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(8, 1fr);
}


/* Room mode */

.remoteVideoGroup.roomMode.remoteVideoGroup-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  height: 100%;
}

.remoteVideoGroup.roomMode.remoteVideoGroup-2,
.remoteVideoGroup.roomMode.remoteVideoGroup-3,
.remoteVideoGroup.roomMode.remoteVideoGroup-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 1%;
}

.remoteVideoGroup.roomMode.remoteVideoGroup-5,
.remoteVideoGroup.roomMode.remoteVideoGroup-6 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.remoteVideoGroup.roomMode.remoteVideoGroup-7,
.remoteVideoGroup.roomMode.remoteVideoGroup-8,
.remoteVideoGroup.roomMode.remoteVideoGroup-9 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.remoteVideoGroup.roomMode.remoteVideoGroup-10,
.remoteVideoGroup.roomMode.remoteVideoGroup-11,
.remoteVideoGroup.roomMode.remoteVideoGroup-12 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

.remoteVideoGroup.roomMode.remoteVideoGroup-13,
.remoteVideoGroup.roomMode.remoteVideoGroup-14,
.remoteVideoGroup.roomMode.remoteVideoGroup-15,
.remoteVideoGroup.roomMode.remoteVideoGroup-16 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}


/* Content share in room mode */

.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-0 {
  display: none;
}

.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-1,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-2,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-3,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-4 {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-5,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-6,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-7,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-8,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-9,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-10,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-11,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-12,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-13,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-14,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-15,
.remoteVideoGroup.roomMode.isContentShareEnabled.remoteVideoGroup-16 {
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(2, 1fr);
}


/* Screen share mode */

.remoteVideoGroup.screenShareMode.remoteVideoGroup-1,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-2,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-3,
.remoteVideoGroup.screenShareMode.remoteVideoGroup-4 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(4, 1fr);
}


/* Child elements */

.instruction {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  color: var(--color_white);
}

.remoteVideoGroup.screenShareMode .instruction {
  font-size: 1rem;
}
/* .attendeeTileView.attendeeTileViews-1,
.attendeeTileView.attendeeTileViews-2
{
  display: grid;
  grid-template-columns: repeat(2, 0.5fr);

justify-content: center;
} */
/* .attendeeTileView.attendeeTileViews-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
  justify-content: center;
  height: 100%; */
  /* width: calc(200vh); */
/* } */
/* .attendeeTileView.attendeeTileViews-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-3{
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(1, 1fr);
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-4{
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-5,
.attendeeTileView.attendeeTileViews-6{
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-7,
.attendeeTileView.attendeeTileViews-8{
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-9{
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-10{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-11,
.attendeeTileView.attendeeTileViews-12{
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-13,
.attendeeTileView.attendeeTileViews-14{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-15{
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-content: center;
  margin: 0.5%;
}
.attendeeTileView.attendeeTileViews-16{
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  justify-content: center;
  margin: 0.5%;
} */
