/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */


 .userlistMain {

  display: flex;
    flex-direction: row;
}
.userlistMain .userlist{
    display: flex;
    flex-direction: column;    
    overflow-y: scroll;
    border-right: 1px solid #222;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;    
    -ms-overflow-style: none;
    scrollbar-width: none;
 }

 .userlistMain .userlist .user.active{    
    background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
 }

 .userlistMain .userlist .user{
    margin: 4px;
    height: 35px;
    width: 35px;
    background: linear-gradient(#FF004A, #ff004aad);
    text-align: center;
    vertical-align: middle;
    border-radius: 3px;
    cursor: pointer;
    line-height: 32px;
    color: #fff;
 }

 .user .typing {
    float: left;
    position: relative;
    margin: -7px 0 0 3px;
 }

 .typinggroup {
    position: absolute;
    margin: 28px 0 0 -1px;
    height: 10px;
 }

 .istyping {
    float: left;
    position: absolute;
    bottom: -45px;
    padding: 0px 1px 1px 5px;
    opacity: 0.5;
        font-size: 14px;

 }  

 .istyping img {
    position: absolute;
    height: 10px;
    width: 35px;
    margin: 8px 0px 0px 5px;
 }

 .userlist .user img{
    width: 30px;
 }

 .userlist .countuser {
    position: relative;
    font-size: 11px;
    background: repeating-linear-gradient(#0445ba,#0445ba, #00000024 100px);
    color: #fff;
    padding: 1px;
    min-width: 15px;
    border-radius: 50%;
    margin: -39px 0 0 23px;
    text-align: center;
    height: 18px;
    line-height: 16px;
 }

.chat {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border-top: 1px solid var(--color_mine_shaft_light); */
  /* height: 617px; */
  margin-top: 50px;
  /* background: pink; */
  position: absolute;
}

/* .margintop {
} */

.bottom {
  color: red;
  position: fixed;
  bottom: 84px;
  margin-bottom: -29px;
  background: green;
  /* height: 30px; */
  width: 100%;
}

.messages {
    flex: 1 1 auto;
    overflow-y: auto;
    padding-top: 0.5rem;
    height: calc(90vh - 13rem);
    margin-top: 15px;
    scrollbar-width: none;
    /* z-index: 1; */
}

@media screen and (min-height: 900px) {
  .messages {
    height: 695px !important;
  }
}

.messageWrapperSender {
  padding: 0.5rem 1.25rem;
  margin-right: -12px;
}

.messageWrapperReceiver {
  padding: 0.5rem 1.25rem;
  margin-left: -14px;
}

.messageWrapperSender .left {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  width: auto;
  left: -4px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center;
}

.messageWrapperReceiver .left {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: relative;
  width: auto;
  left: 4px;
  top: 0px;
  flex: none;
  order: 0;
  align-self: center;
}

.messageWrapperSender .right .names {
  text-transform: capitalize;
}

.messageWrapperReceiver .right .names {
  text-transform: capitalize;
}

.messageWrapperSender .right {
  position: relative;
  order: 1;
  float: right;
  border-radius: 50%;
  margin: 0 auto;
  padding: 8px;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #fff;
}

.messageWrapperReceiver .right {
  position: relative;
  order: 1;
  float: left;
  border-radius: 50%;
  margin: 0 auto;
  padding: 8px;
  height: 40px;
  width: 40px;
  text-align: center;
  color: #fff;
  z-index: 0;
}

.messageWrapperSender .senderName {
  position: relative;
  height: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #383838;
  flex: none;
  order: 0;
  align-self: flex-end;
  margin: 1px 2px;
  z-index: 0;
}

.messageWrapperReceiver .senderName {
  position: relative;
  height: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: #383838;
  flex: none;
  order: 0;
  align-self: flex-start;
  margin: 1px 2px;
}

.messageWrapperSender .message {
  width: 210px;
  display: table;
  flex-direction: row;
  padding: 6px;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  background: #7aafc470;
  border-radius: 6px;
  flex: none;
  order: 2;
  align-self: flex-end;
  margin: 5px 5px;
  text-align: justify;
  color: black;
  font-size: 15px;
}

.messageWrapperReceiver .message {
  width: 210px;
  display: table;
  flex-direction: row;
  padding: 6px;
  position: relative;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  background: #ffa50070;
  border-radius: 6px;
  flex: none;
  order: 2;
  align-self: flex-start;
  margin: 5px 5px;
  text-align: justify;
  color: black;
  font-size: 15px;
}

.messageWrapperSender .date {
  position: static;
  width: 45px;
  height: 16px;
  left: 45px;
  top: 97px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: right;
  /* Black/30% */
  color: rgba(0, 0, 0, 0.3);
  /* Inside Auto Layout */
  flex: none;
  order: 3;
  align-self: flex-end;
  margin: 0px 3px;
}

.messageWrapperReceiver .date {
  position: static;
  width: 45px;
  height: 16px;
  left: 45px;
  top: 97px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  /* identical to box height, or 160% */
  text-align: right;
  /* Black/30% */
  color: rgba(0, 0, 0, 0.3);
  /* Inside Auto Layout */
  flex: none;
  order: 3;
  align-self: flex-start;
  margin: 0px 3px;
}

.timecolor {
  color: #999;
  margin: 10px;
}

.blueColor {
  background: #28a2dc !important;
}
.orangeColor {
  background: orange !important;
}

.messageWrapper.raiseHand .message {
  font-size: 2rem;
}

.chatInput {
  /*flex: 0 0 auto;*/
  float: left;
  position: fixed;
  bottom: 0px;
}
