/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.remoteVideo {
  display: none !important;
  position: relative;
  background: transparent;
  /*overflow: hidden;*/
}

.muted {
  float: left;
    text-align: left !important;
    position: absolute;
    top: 1px !important;
    left: -6px !important;
    background: #ff0000b8;
    border-radius: 5px;

}


.attendeeName {
  position: absolute;
    top: 0%;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
    /* padding: 50%; */
    /* background: pink; */
    padding: 16%;
    font-size: 30px;
    text-transform: capitalize;
}
.attendeeName1 {
  position: absolute;
    top: 44%;
    left: 47%;
    /* justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%; */
    /* padding: 50%; */
    /* background: pink; */
    /* padding: 16%; */
    font-size: 90px;
    text-transform: capitalize;
}

.participantStatus {

    position: relative;
    background: var(--video-name-plate-background-color);
    width: 94%;
    z-index: 9999;
    margin-top: -28px;
    border-radius: 4px;
    margin-left: 3%;
}


.displaynone {
    display: none !important;
}

.remoteVideo.roomMode {
  border-radius: 0.25rem;
}

.remoteVideo.screenShareMode {
  border-radius: 0.25rem;
}

.remoteVideo.enabled {
  display: block !important;
  margin: 1%;
  width: 100%;
    height: 127px;
    position: absolute;
}

.participants {
  position: relative;
    width: 100%;
    height: 131px;
    top: 2px;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(40px);
    /*border-radius: 8px;*/
    margin: 1%;
    z-index: 9;
}

.video {
  display: block;
    position: unset;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 8;
    width: 188px;
    height: 127px;
}

.ddtt {
    font-size: 30px;
    background: red;
}


.video1 {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  object-fit: cover;
  z-index: 8;
}


.video i {
    color: #000;
    top: 40%;
    float: left;
    position: absolute;
    left: 4%;
}

.attname {
    color: #000;
    top: 0%;
    float: left;
    position: absolute;
    left: 4%;
}

.muted {
  text-align: center;
  flex: 0 0 1.5rem;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  width: 1.5rem;
}

.nameplate {
  z-index: 1;
  align-items: center;
  position: absolute;
  max-width: 95%;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: var(--color_black_medium_opacity);
  backdrop-filter: blur(0.5rem);
}

.remoteVideo.roomMode .nameplate {
  display: flex;
}

.remoteVideo.screenShareMode .nameplate {
  display: none;
}

.remoteVideo.medium .nameplate {
  padding: 0.25rem 0.3rem 0.25rem 0.5rem;
  bottom: 0.25rem;
  font-size: 0.8rem;
  border-radius: 0.5rem;
}

.remoteVideo.large .nameplate {
  padding: 0.5rem 0.75em 0.5em 1rem;
  bottom: 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  z-index: 9;
}

.marginleft {
  left: 12%;
}

.fullscreen {
    position: fixed;
    /*z-index: 9;*/
}


.fullscreen1 {
    position: fixed;
    bottom: 19% !important;
    left: 12%;
}

.fullscreenTwo {
  position: absolute;
    bottom: 10% !important;
    left: 22%;
}

.displaynone {
    display: none !important;
}

/* .margintop {
    
    left: 28%;
    margin-top: 7px;
} */

.margintop1 {
    left: 100px;
    bottom: 75px !important;
}

.remoteVideo.small .nameplate {
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled .nameplate {
  display: none;
}

.remoteVideo.screenShareMode.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.remoteVideo.roomMode.isContentShareEnabled.large .nameplate {
  display: flex !important;
  padding: 0.2rem 0.2rem 0.2rem 0.3rem;
  bottom: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
}

.name {
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto;
  text-overflow: ellipsis;
      margin-left: 30px;

}

.nameStatus {
  height: 23px;
}

.muted {
  flex: 0 0 1.25rem;
  width: 1.40rem;
    text-align: center !important;
}

.remoteVideo.small .name + .muted {
  margin-left: 0.25rem;
  text-align: center !important;
}

.remoteVideo.medium .name + .muted {
  margin-left: 0.25rem;
text-align: center !important;

}

.remoteVideo.large .name + .muted {
  margin-left: 0.5rem;
}

.raisedHand {
  z-index: 1;
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  font-size: 2rem;
  animation: shake 1.22s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  user-select: none;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-0.5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1.5px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1.5px, 0, 0);
  }
}
