/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.deviceList {
  padding: 1rem;
    /*margin-top: -2% !important;*/
    /*float: left;*/
    width: 100%;
}

.device-list {
  width: 100%;
    margin-top: 15px;
}

.device-list .device-name{
  float: left;
    width: 100%;
        height: 29px;
    font-weight: bold;
    font-size: 15px;

}

.control {
  background-color: transparent;
  cursor: pointer;
  outline: none !important;
  box-shadow: none !important;
  transition: none;
  border-radius: 0.25rem;
  font-size: 0.9rem;
  padding: 0.5rem;
  border: 1px solid #ccc !important;
  height: 50px;
  line-height: 38px;
  margin-top: 10px;
}

.control:hover {
  /*background-color: var(--baseDarkColor);*/
}

.placeholder {
  /*color: var(--color_alto1);*/
  color: #222;
}

.arrow {
  border-color: var(--color_alto) transparent transparent;
  border-width: 0.3rem 0.3rem 0;
  margin-top: 2px;
  margin-right: 0.25rem;
}

.dropdown[class~='is-open'] .arrow {
  border-color: var(--color_alto) transparent transparent !important;
  border-width: 0.3rem 0.3rem 0 !important;
}

.menu {
  margin: 0;
  padding: 0.5rem;
  color: var(--color_alto);
  /*background-color: var(--color_cod_gray_medium);*/
  box-shadow: 0 0.25rem 0.5rem var(--color_black_low_opacity);
  overflow: hidden;
  font-size: 0.9rem;
  border: none;
  max-height: none;
  border-radius: 0.25rem;
}

.menu *[class~='Dropdown-option'] {
  /*color: var(--color_silver_chalice);*/
  color: #222;
  border-radius: 0.25rem;
}

.menu *[class~='Dropdown-option']:hover {
  background-color: var(--background-color-gradiant-right);
  color: #fff;
}

.menu *[class~='is-selected'] {
  background-color: transparent;
  /*color: var(--color_alabaster);*/
  color: #c3c0c0;
}

.menu *[class~='is-selected']:hover {
  background-color: var(--background-color-gradiant-right);
  color: #fff;
}
