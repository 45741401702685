/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.tooltip[class~='rc-tooltip-placement-top'] div[class~='rc-tooltip-arrow'],
.tooltip[class~='rc-tooltip-placement-topLeft'] div[class~='rc-tooltip-arrow'],
.tooltip[class~='rc-tooltip-placement-topRight']
  div[class~='rc-tooltip-arrow'] {
  border-top-color: #fff;
}

.tooltip *[class~='rc-tooltip-inner'] {
  background-color: var(--background-color-gradiant-right);
  font-size: 1rem;
  border: 1px solid #9ba3b5;
}
