/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.contentVideo {
  display: block;
  /*background-color: var(--color_black);*/
  width: 100%;
  height: 100%;
  z-index: 1;
    /* float: right; */
    position: relative;
}

.video {
  display: block;
  width: 97%;
  height: 100%;
  object-fit: contain;
  margin: 0 auto;
  /* padding-top: 70px; */
 height: calc(100vh - 4.5rem);
}

.fullscreenShare {
  position: fixed !important;
  z-index: 0 !important;
}

.smallscreen {
	width: 192px ;
    height: 131px;
    position: absolute !important;
    top: -16.5rem !important;
    left: 8px !important; 
}

.fsleft {
  margin-left: 84px !important;
}
.fstop {
  margin-top: -137px !important; 
}

.width {
  width: 97.7% !important;
}

.fstopsc {
  margin-top: -151px !important;  
}

@media (min-height: 900px) {  
  .manageWidthWhileRightSideEnagle {
      /*width: 97% !important;*/
  }
  .contentVideo .video{
        width: 86%;
        margin: 0 auto;
  }
  .fullscreenButton{
      bottom: 8% !important;
    left: 16% !important;
  }
}

@media (max-height: 800px) {  
  .contentVideo .video{
    width: 92%;
    margin: 0 auto;
    height: calc(90vh - 4.5rem);
    object-fit: contain;
    margin-top: 83px;
  }
  .contentVideo .smallVideo{
    width: 92%;
    margin: 0 auto;
    height: calc(15vh);
    object-fit: contain;
    margin-top: 275px;
  }

}
@media (min-height: 800px) {  
  .contentVideo .video{
    width: 92%;
    margin: 0 auto;
    height: calc(90vh - 4.5rem);
    object-fit: contain;
    margin-top: 75px;
  }
  .contentVideo .smallVideo{
    width: 92%;
    margin: 0 auto;
    height: calc(15vh);
    object-fit: contain;
    margin-top: 265px;
  }

}
