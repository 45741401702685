/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.chatInput {
  /* width: 100%;
  height: 100%;
  padding: 0.75rem; */
  position: absolute;
  /*bottom: 0px;*/
  width: 320px;
  height: 48px;
  /* left: 1112px; */
  /*top: 620px;*/
  /* White/100% */
  background: #FFFFFF;
  box-shadow: 0px -0.5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 12px 0px;
}

.forms {
  /*display: flex;*/
  /* background-color: var(--color_tundora); */
  border-radius: 0.25rem;
  padding: 0px !important;
  margin-left: 0px !important;
}

.input {
  flex: 1 1 auto;
  /* color: var(--color_alabaster);
  background-color:  var(--color_rightChat); */
  outline: none;
  border: none;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  width: 100%;
    float: left;
    bottom: 0px;
    position: absolute;
    border: 10px solid #ccc;
    border-radius: 10px 10px 0px 0px;
}

.input::placeholder {
  color: var(--color_silver_chalice);
}

.raiseHandButton {
  flex: 0 0 auto;
  font-size: 1.5rem;
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  margin-top: 0.25rem;
  float: right;
}

.raiseHandButton span {
  transition: all 0.05s;
  filter: grayscale(100%);
}

.raiseHandButton span:hover {
  filter: grayscale(0);
}

.raiseHandButton.raised span {
  filter: grayscale(0) !important;
}

.attachIcon {
  position: absolute;
  width: 16px;
  height: 16px;

/* Black/30% */

background: url('../public/icons/icon-attach.png');
}