/*!
 * Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 * SPDX-License-Identifier: Apache-2.0
 */

.localVideo {
  display: block;
  width: calc(var(--local_video_container_height) - 0.5rem);
  height: calc(var(--local_video_container_height) - 0.5rem);
  background: var(--video-background-color);
  /* var(--color_black); */
  border-radius: 6px; 
  overflow: hidden;
  display: block !important;
  margin: 4px -2px 4px 4px;
  z-index: 4;
  width: 100%;
  height: 137px;
  position: relative;
}
.videoTileview {
  display: block;
  width: calc(var(--local_video_container_height) - 0.5rem);
  height: calc(var(--local_video_container_height) - 0.5rem);
  background: var(--video-background-color);
  /* var(--color_black); */
  border-radius: 6px; 
  overflow: hidden;
  display: block !important;
  margin: 1%;
  z-index: 4;
  width: 99%;
  height: 98%;
  position: relative;
}
.localVideo.enabled {
  display: block !important;
      margin: 4px -2px 4px 4px;
      z-index: 4;
    position: relative;

}

.video {
  display: block;
  width: 100%;
    height: 138px;
  /* border-radius: 0.25rem; */
   border-radius: 6px; 
  object-fit: cover;
  /* margin-left: 2px; */
}


.namemain {

  /* float: left; */
  position: absolute;
  bottom: 35px;
  width: 94%;
  /* background: red; */
  /* left: 2%; */
  height: 0;
  z-index: 2;
  margin-left:3%;

}

.namemain1 {

  /* float: left; */
  position: absolute;
  bottom: 52px;
  width: 94%;
  /* background: red; */
  /* left: 2%; */
  height: 0;
  z-index: 2;
  margin-left:3%;

}

.namemain .host {
  /* float: left; */
  width: 20%;
  background: #207ada;
  border-radius: 7px 0px 0px 6px;
  padding: 5px;
  text-align: center;
  font-size: 14px;
  height: 30px;
  position:inherit;
}
.namemain1 .host1 {
  /* float: left; */
  width: 20%;
  background: #207ada;
  border-radius: 7px 0px 0px 6px;
  padding: 5px;
  text-align: center;
  font-size: 19px;
  height: 37px;
  position:inherit;
}


.namemain .name {
  width: 80%;
  float: right;
  background: #0006;
  color: #fff;
  padding: 3px;
  font-size: 14px;
  margin-left: 8px;
  border-radius: 5px;
  /* padding-left: 30px;
  margin-top:0px; */
}
.namemain1 .name1 {
  width: 80%;
  float: right;
  background: #0006;
  color: #fff;
  padding: 5px;
  font-size: 19px;
  /* margin-left: 100px; */
  border-radius: 5px;
  /* padding-left: 100px; */
  /* margin-top: 0px; */
}